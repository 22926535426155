import type { DefaultDocument } from '../defaultDocument.interface'
import type { UserLanguages } from '../language.interface'
import type { IPlan, IPlanPopulated } from './plan.interface'

export enum UserRoles {
	USER = 'user',
	ADMIN = 'admin',
}

export interface IUser extends DefaultDocument {
	_id: string
	userName: string
	email: string
	password?: string
	avatar?: string
	role: UserRoles
	disabled: boolean
	banned: boolean
	lang: UserLanguages
	location: string
	celebrity: boolean
	firebaseToken: string
	document: {
		docType: string
		number: string
	}
	points: number
	hasSubscription: boolean
	hasPassword: boolean
	bannedAt: Date
	viewedPresetWarning: boolean
	createdAt: Date
	updatedAt: Date
	viewedInstructions: boolean
	newsletterSubscription: boolean
	modals: {
		subscriptionOfferModalDisplayedAt: Date | null
		featured: string | null
		lastViewedUpdate: string | null
	}
	passwordResetCount: number
	lastPasswordResetAt: Date | null
	plan: IPlanPopulated | null
}
